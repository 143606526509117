import React from "react";
import Layouts from "../components/Layouts";
import Seo from "../components/Seo";

const PrivacyPolicyPage = () => {
	return (
		<>
			<Seo title="Privacy Policy" />
			<Layouts lang="en">
				<div className="  my-12 px-0  w-10/12 xl:w-9/12 2xl:w-9/12 mx-auto">
					<div className="w-full prose lg:prose-lg">
						<h1>Privacy Policy</h1>
						<p>
							Kashat app is a free app built, managed and operated by Pharos
							Microfinance S.A.E. This service is provided at no cost and is
							intended for use as is. This page is used to inform customers and
							get their consent regarding our policies with the collection, use,
							and disclosure of Personal Information upon using our service.
						</p>

						<p>
							If you choose to use our Service, then you agree to the collection
							and use of information in relation to this policy.
						</p>

						<p>
							The terms used in this Privacy Policy have the same meanings as in
							our Terms of Use unless otherwise defined in this Privacy Policy.
						</p>

						<p>
							<strong>
								<u>Information We Collect:</u>
							</strong>
						</p>

						<p>We may collect and use the following data about you:</p>

						<ol>
							<li>
								Information that you provide by filling in forms in the App or
								on our website. This includes information provided at the time
								of registering to use our App, subscribing to our services and
								corresponding with us via e-mail or helpline. This may include,
								but is not limited to, information such as your name, address,
								phone, email address, national ID card, photograph of you,
								username and password.
							</li>
						</ol>

						<ol start="2">
							<li>
								Upon subscribing to our services (such as applying for a loan)
								we may ask you to provide us with additional information about
								you such as your educational background, occupational status and
								field, marital status, financial and credit information. We may
								require you to provide documentary evidence as specified under
								the Microfinance regulations, and these may be subject to change
								from time to time.
							</li>
						</ol>

						<ol start="3">
							<li>
								Information that we collect about you and your device upon using
								our App after you grant us access permissions. Please note that
								this information cannot and will not be collected without user
								consent. This includes but is not limited to:
							</li>
						</ol>

						<ul>
							<li>
								device information including type of mobile, unique device
								identifiers (IMEI or serial number), device operating system and
								device’s location and time zone
							</li>
							<li>mobile network information</li>
							<li>
								stored information on your device such as contact list, call
								logs, SMS logs and media gallery
							</li>
							<li>
								current location information provided by GPS technology and
								other location services
							</li>
						</ul>

						<ol start="4">
							<li>
								Information regarding details of transactions that you carry out
								through our App and if you contact us via our helpline, we may
								keep a record of that correspondence.
							</li>
							<li>
								Unique application numbers. When you install or uninstall a
								Service containing a unique application number or when such a
								Service searches for automatic updates, that number and
								information about your installation, for example, the type of
								operating system, may be sent to us.
							</li>
						</ol>

						<ol start="6">
							<li>
								Log Data. We may collect certain information (log data)
								automatically and store it in log files. Log Data may include
								information such as your device Internet Protocol (“IP”)
								address, Internet Service Provider (ISP), device name, operating
								system version, browser type, click stream data and the time and
								date of your use of the Service. This is statistical data about
								our users’ browsing actions and patterns, and does not identify
								any individual. This information when used in combination with
								other collected information will provide better analysis and
								security.
							</li>
							<li>
								This Service does not use these “cookies” explicitly. However,
								in order to provide better user experience, we may use third
								party service providers such analytics providers or marketing
								agencies that may opt to use cookies or any other mobile
								tracking technology to distinguish you from other users on the
								App or website.
							</li>
						</ol>

						<p>
							<strong>
								<u>Purposes and Uses for collecting the Information:</u>
							</strong>
						</p>

						<p>
							We collect the above-mentioned information about you for the
							following purposes and uses:
						</p>

						<ul>
							<li>
								To be able to verify your identity and detect, prevent, mitigate
								and investigate fraudulent or illegal activities
							</li>
						</ul>

						<ul>
							<li>
								To able to determine whether your loan application is
								approved/rejected. In case of a loan application approval, the
								information we collect will determine your credit score along
								with your assigned loan limit
							</li>
							<li>
								To be able to build our algorithm system and credit models
							</li>
							<li>To be able to analyze credit and customer behavior</li>
							<li>
								To be able to process transactions, disburse loans and collect
								payments
							</li>
							<li>
								To be able to comply with applicable rules and regulations
								regarding KYC “Know Your Customer”, AML “Anti-money laundering”
								requirements and CFT (Combating the Finance of Terrorism)
							</li>
							<li>
								To be able to send you promotional communications with respect
								to services that may be of interest to you
							</li>
						</ul>

						<p>
							<strong>
								<u>Disclosure of your Information</u>
							</strong>
						</p>

						<p>
							We attempt to minimize the amount of personal information we
							disclose to what is directly relevant and necessary to accomplish
							the specified purposes. We may share and disclose any Customer
							Information with the following recipients:
						</p>

						<ul>
							<li>
								We may disclose some or all of the collected information to any
								of our subsidiaries, our parent company and any of our parent
								company’s other subsidiaries (“our group”)
							</li>
						</ul>

						<ul>
							<li>
								If applicable, we may disclose some or all of the data we
								collect from you to connect to mobile wallet providers,
								insurance companies, credit bureaus or other financial
								institutions
							</li>
						</ul>

						<ul>
							<li>
								We may disclose your personal information to any local or
								international law enforcement or governmental agencies in order
								to assist in the detection, prevention and investigation of
								criminal activities or fraud
							</li>
						</ul>

						<ul>
							<li>
								In the event that we sell or buy any business or assets, in
								which case personal data held by us about our customers will be
								one of the transferred assets that we may disclose to the
								prospective seller or buyer of such business or asset.
							</li>
						</ul>

						<ul>
							<li>
								We may disclose your personal data with third-party service
								providers under contract with us that help us with our business
								operations, such as transaction processing, fraud prevention,
								and marketing. Please note that in such cases we will only share
								a minimized amount of data of what is directly relevant and
								necessary to accomplish the specified purposes.
							</li>
						</ul>

						<ul>
							<li>
								If we are under a duty to disclose or share your personal data
								in order to comply with any legal or regulatory obligation, or
								in order to enforce or apply our terms of use and other
								agreements; or to investigate potential breaches or report
								defaulters to any credit bureau; or to protect the rights,
								property or safety of us, our customers, or others. This
								includes exchanging information with other companies and
								organizations for the purposes of fraud protection and credit
								risk reduction.
							</li>
						</ul>

						<p>
							<strong>
								<u>Storing Your Data</u>
							</strong>
						</p>

						<ol>
							<li>
								The personal data that we collect from you are stored on servers
								within Egypt, but it may be transferred to, and stored at, a
								destination outside Egypt. It may also be processed by staff
								operating outside Egypt who work for us or for one of our
								suppliers. These staff members may be engaged in the fulfillment
								of your requests. By submitting your Personal Data, you agree to
								this transfer, storing or processing of data. We will take all
								steps reasonably necessary to ensure that your data is treated
								securely and in accordance with this Privacy Policy.
							</li>
						</ol>

						<ol start="2">
							<li>
								We ask you not to share your personal password or PIN that you
								have created to access your account on our App with anyone and
								we ask you to keep it confidential to ensure security and
								prevent unauthorized access. Although we will do our best to
								protect your personal data, please remember that no method of
								transmission over the internet, or method of electronic storage
								is 100% secure and reliable thus we cannot guarantee the
								security of your data transmitted to our sites; any transmission
								is at your own risk. Once we have received your information, we
								will use strict procedures and security features to try to
								prevent unauthorized access.
							</li>
						</ol>

						<ol start="3">
							<li>
								By uninstalling the app from your Device, you can withdraw your
								consent from accessing your Customer Information in the future.
								We may keep and share stored Customer Information after the
								point of uninstall for as long as necessary for the fulfillment
								of the purposes for which Customer Information was obtained or
								for the establishment, exercise or defense of legal claims, or
								for legitimate business purposes, or as permitted by law.
							</li>
						</ol>

						<p>
							<strong>
								<u>Customer Obligations and Rights</u>
							</strong>
						</p>

						<ul>
							<li>
								You are obliged to inform us promptly, and in any event, within
								30 days, in writing if there are any changes to Customer
								Information provided to us and to respond promptly to any
								request from us. Failing to do so can and may result in blocking
								or closing your account where permitted under local Laws.
							</li>
							<li>
								If you withhold or withdraw any consent that we may need to
								process your customer information, we will be unable to provide
								new or continue to provide our services to you and we reserve
								the right to terminate our relationship with you
							</li>
							<li>
								If we have suspicions regarding financial crime or an associated
								risk, we will take necessary actions to meet the compliance
								obligations and may block or close your account where permitted
								under local laws.
							</li>
							<li>
								Should we wish to use your information for marketing purposes,
								we will inform you prior to such use. You shall be entitled to
								prevent such usage by informing us, within 7 days of being
								informed of the proposed use, that you do not wish to disclose
								such information.
							</li>
						</ul>

						<p>
							<strong>
								<u>Changes and Questions regarding our Privacy Policy</u>
							</strong>
						</p>

						<p>
							Any changes we may make to our privacy policy in the future will
							be posted on this page, and where appropriate, notified to you
							when you next start the App. The new terms may be displayed
							on-screen and you may be required to read and accept them to
							continue your use of the App or the Services.
						</p>

						<p>
							Questions, comments and requests regarding this privacy policy are
							welcomed and should be addressed to info@kashat.com.eg
						</p>
					</div>
				</div>
			</Layouts>
		</>
	);
};
export default PrivacyPolicyPage;
